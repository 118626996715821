import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Icon from "../utilities/icons"

import {
  Hero,
  PriceComparisonBlock,
  Testimonials,
  Gallery,
  BeforeAfter,
  Citations,
  FAQ,
} from "../components/styles/LandingPageStyles"
import SEO from "../components/seo"

//images

const servicesLandingPage = () => {
  return (
    <Layout>
      <SEO
        title="Pat Testing Newark | MJ Electrical"
        description="Mobile beauty therapist, offering Lash Lift in Newark, Claypole, Fernwood, Winthorpe, Coddington, Collingham, Elston, Muskham and other surrounding areas."
      />
      <Hero leadImage={""} color_type="brand_gradient">
        <div className="inner-container">
          <div className="heroLeft">
            <h1>PAT Testing Newark</h1>
            <h2>Portable Appliance Testing</h2>
            <p>
              Portable appliance testing (PAT), is a legal requirement which was
              put in place to ensure companies and organisations comply with the
              Electricity at Work Regulations 1989. It places responsibility on
              employers and companies to regularly inspect, test and maintain
              electrical appliances.
            </p>
            <p>
              At MJ Electrical we aim to offer competitive quotes and a cost
              effective solution to help you meet your PAT testing requirements.
            </p>

            <div className="global-btn-wrapper">
              <Link
                className="global-btn global-btn_white"
                to="/contact"
                state={{ service: "PAT Testing" }}
              >
                Get in touch
              </Link>
            </div>
          </div>
          <div className="heroRight" />
        </div>
      </Hero>

      <Hero leadImage={""} color_type="grey">
        <div className="inner-container">
          <div className="heroLeft">
            <h2>
              Examples of situations where appliances will be required to be PAT
              tested are as follows:
            </h2>
            <ul>
              <li>- Used by employees. </li>
              <li>
                - Used by the public (appliances in schools hotels, shops etc)
              </li>{" "}
              <li>
                - Appliances that are to be hired. PAT testing is an essential
                way of ensuring electrical safety, which will enable your
                business to comply with the legal standards.
              </li>
            </ul>
            <div className="global-btn-wrapper">
              <Link
                className="global-btn global-btn_white"
                to="/contact"
                state={{ service: "PAT Testing" }}
              >
                Get in Touch
              </Link>
            </div>
          </div>
          <div className="heroRight" />
        </div>
      </Hero>

      <Testimonials>
        <div className="inner-container">
          <h3>Lash Lift Testimonials</h3>

          <div className="testimonials-grid">
            <div className="testimonial-item">
              <div
                style={
                  {
                    //backgroundImage: `url(${testimonialUserOne})`,
                  }
                }
                className="testimonial-item-avatar"
              />
              <div className="testimonial-item-content">
                <p>
                  <Icon
                    name="top_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />{" "}
                  Hey Gemma, thank you so much for the lash lift and tint you
                  did for me two weeks ago!! My lashes look amazing and I keep
                  getting asked if I’ve got fake lashes on 😄. Definitely going
                  to have this done again!! Thanks again ☺️xx{" "}
                  <Icon
                    name="bottom_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />
                </p>
                <span className="testimonial-item-credit">
                  Isabel Morris <em>(Client)</em>
                </span>
              </div>
            </div>
            <div className="testimonial-item">
              <div
                style={
                  {
                    //backgroundImage: `url(${testimonialUserTwo})`,
                  }
                }
                className="testimonial-item-avatar"
              />
              <div className="testimonial-item-content">
                <p>
                  <Icon
                    name="top_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />{" "}
                  Have just had this done, I love it! I never realised I had
                  long eyelashes 😂{" "}
                  <Icon
                    name="bottom_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />
                </p>
                <span className="testimonial-item-credit">
                  Joy Eveline <em>(Client)</em>
                </span>
              </div>
            </div>
            <div className="testimonial-item">
              <div
                style={
                  {
                    //backgroundImage: `url(${testimonialUserThree})`,
                  }
                }
                className="testimonial-item-avatar"
              />
              <div className="testimonial-item-content">
                <p>
                  <Icon
                    name="top_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />{" "}
                  I've found my lift is a great way to trick people into
                  thinking I'm blessed with Bambi lashes. I have never, ever
                  been complimented on my eyelashes, but since getting a lift,
                  I've had friends and colleagues commenting left and right.{" "}
                  <Icon
                    name="bottom_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />
                </p>
                <span className="testimonial-item-credit">
                  Bella Cacciatore <em>(Glamour Mag)</em>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: "40px" }}
            className="global-btn-wrapper global-btn-wrapper_centered"
          >
            <Link
              className="global-btn global-btn_blue"
              to="/contact"
              state={{ treatment: "Lash Lift" }}
            >
              Book Appointment
            </Link>
          </div>
        </div>
      </Testimonials>

      {/*<Gallery>
        <div className="inner-container">
          <h3>Gallery</h3>
          <p>Gallery Here</p>
        </div>
      </Gallery>*/}

      <Citations>
        <div className="inner-container">
          <p>
            Still undecided whether to get a <b>Lash Lift</b>? All my clients in
            Newark <b>love</b> their results, but don't just take our word for
            it...
          </p>
          <div className="citation-grid">
            <a
              href="https://www.buzzfeed.com/natalyalobanova/eyelash-lift-before-and-after-pictures-that-are-so-damn"
              target="_blank"
              className="citation-item"
            >
              <div className="citation-logo">
                <img src={""} alt="source logo" />
              </div>
              <h3 className="citation-headline">
                17 Lash Lift Before And After Pictures That'll Give You Serious
                Goals{"  "}
                <Icon
                  name="external_link"
                  color="#ffffff"
                  width="24"
                  height="24"
                />
              </h3>
            </a>
            <a
              href="https://www.elle.com/beauty/makeup-skin-care/a19409536/what-is-lash-lift/"
              target="_blank"
              className="citation-item"
            >
              <div className="citation-logo">
                <img src={""} alt="src logo" />
              </div>
              <h3 className="citation-headline">
                Everything You Need to Know About Lash Lifts (and Why They're
                Better Than Lash Extensions){" "}
                <Icon
                  name="external_link"
                  color="#ffffff"
                  width="24"
                  height="24"
                />
              </h3>
            </a>
            <a
              href="https://www.popsugar.co.uk/beauty/What-Lash-Lift-Eyelash-Perm-Before--After-Photos-44329698"
              target="_blank"
              className="citation-item"
            >
              <div className="citation-logo">
                <img src={""} alt="src logo" />
              </div>
              <h3 className="citation-headline">
                I Got an Eyelash Perm, and Holy S**t — the Hype Is So Real{" "}
                <Icon
                  name="external_link"
                  color="#ffffff"
                  width="24"
                  height="24"
                />
              </h3>
            </a>
            <a
              href="https://www.glamour.com/story/what-is-a-lash-lift-review"
              target="_blank"
              className="citation-item"
            >
              <div className="citation-logo">
                <img src={""} alt="" />
              </div>
              <h3 className="citation-headline">
                I Got a Lash Lift, and It’s as Life Changing as Everyone Says{" "}
                <Icon
                  name="external_link"
                  color="#ffffff"
                  width="24"
                  height="24"
                />
              </h3>
            </a>
          </div>
          <div className="global-btn-wrapper global-btn-wrapper_centered">
            <Link
              className="global-btn global-btn_white"
              to="/contact"
              state={{ treatment: "Lash Lift" }}
            >
              Book Appointment
            </Link>
          </div>
        </div>
      </Citations>

      <FAQ>
        <div className="inner-container">
          <h3>FAQ</h3>
          <div className="faq-item">
            <div className="faq-question">
              Are you qualified in doing Lash Lift?
            </div>
            <div className="faq-answer">
              YES! Fully trained and insured with all the relevant
              certifications...
            </div>
          </div>
          <div className="faq-item">
            <div className="faq-question">How long does it take?</div>
            <div className="faq-answer">It takes around 1 hour 15 minutes.</div>
          </div>
          <div className="faq-item">
            <div className="faq-question">How long does it last?</div>
            <div className="faq-answer">Around 6 - 8 Weeks.</div>
          </div>
          <div className="faq-item">
            <div className="faq-question">Where do you operate?</div>
            <div className="faq-answer">
              Newark and surrounding areas, including Balderton, Claypole,
              Farndon, Muskham, Elston, Collingham, Winthorpe, Coddington, Long
              Bennington! Get in touch if you're in other areas I'm sure we can
              work something out.
            </div>
          </div>
          <div className="faq-item">
            <div className="faq-question">
              Why are you a lot cheaper than a salon?
            </div>
            <div className="faq-answer">
              Simply - Less overheads! As a mobile beautician I come to you, in
              your own home so don't have the expensive salon costs.
            </div>
          </div>
          <div className="faq-item">
            <div className="faq-question">Can you still wear mascara?</div>
            <div className="faq-answer">
              Yes you can, but I'd advise using an oil free make up remover to
              remove the mascara.
            </div>
          </div>
          <div className="faq-item">
            <div className="faq-question">
              Do you tint the eyelashes as well?
            </div>
            <div className="faq-answer">Yes!</div>
          </div>
          <p style={{ textAlign: "center" }}>
            <b>
              Have a question about lash lift? Get in touch to ask me anything!
            </b>
          </p>
          <div
            style={{ marginTop: "40px" }}
            className="global-btn-wrapper global-btn-wrapper_centered"
          >
            <Link
              className="global-btn global-btn_blue"
              to="/contact"
              state={{ treatment: "Lash Lift" }}
            >
              Book Appointment
            </Link>
          </div>
        </div>
      </FAQ>
      <p
        style={{ textAlign: "center", marginTop: "20px", fontSize: "14px" }}
        className="landing-disclaimer"
      >
        Other salon's prices correct as of Sept 2019
      </p>
    </Layout>
  )
}

export default servicesLandingPage
